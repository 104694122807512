
// import React, { useEffect } from 'react';

import React from 'react';
import HomePage from './HomePage';

const RedirectPage = () => {
  // useEffect(() => {
  //   window.location.replace('https://www.google.com');
  // }, []);

  // return <div>Loading...</div>;
  return <HomePage/>
};

export default RedirectPage;
